import React, { useState, useEffect, useContext } from "react"
import { DataContext } from "../context/DataContext"
import Charts from "./charts/Charts"
import Controls from "../components/controls/Controls"
import { Paper, Box, Typography, Divider } from "@mui/material"
import { useParams } from "react-router-dom"
import {
  countPlayerMatches,
  countPlayerTrainings,
  countTotalPresences,
  countPlayerCallUps,
} from "./statsFunctions"
import { set } from "date-fns"

const MiniPlayerStats = ({ teamId }) => {
  const { id } = useParams()
  const { matches, players, trainings } = useContext(DataContext)
  const [playerMatchesCount, setPlayerMatchesCount] = useState(0)
  const [playerTrainingsCount, setPlayerTrainingsCount] = useState(0)
  const [matchTypeCounts, setMatchTypeCounts] = useState([])
  const [presenceCounts, setPresenceCounts] = useState([])
  const [countPlayerPresences, setCountPlayerPresences] = useState(0)
  const [countPlayerNonPresences, setCountPlayerNonPresences] = useState(0)
  const [presencesPercentual, setPresencesPercentual] = useState(0)
  const [countPlayerCallups, setCountPlayerCallups] = useState(0)
  const [callupsPercentual, setCallupsPercentual] = useState(0)

  useEffect(() => {
    if (matches) {
      const playerId = id

      const filteredTrainings = teamId
        ? trainings.filter(
            (item) => item.team_id === teamId && item.done === "Fatto"
          )
        : trainings.filter((item) => item.done === "Fatto")

      const filteredMatches = teamId
        ? matches.filter((item) => item.team_id === teamId)
        : matches

      const { matchCount, matchTypeArray } = countPlayerMatches(
        filteredMatches,
        playerId
      )
      const { trainingCount, presenceArray } = countPlayerTrainings(
        filteredTrainings,
        playerId
      )
      const totalPresences = countTotalPresences(filteredTrainings, playerId)
      const callUps = countPlayerCallUps(filteredMatches, playerId)
      setPlayerMatchesCount(matchCount)
      setPlayerTrainingsCount(trainingCount)
      setMatchTypeCounts(matchTypeArray)
      setPresenceCounts(presenceArray)
      setCountPlayerPresences(totalPresences)
      setCountPlayerNonPresences(filteredTrainings.length - totalPresences)
      const presencePercent = (totalPresences / filteredTrainings.length) * 100
      setPresencesPercentual(parseFloat(presencePercent.toFixed(2)))
      setCountPlayerCallups(callUps)
      const callupsPercent = (callUps / filteredMatches.length) * 100
      setCallupsPercentual(parseFloat(callupsPercent.toFixed(2)))
    }
  }, [matches, trainings])

  return (
    <Paper elevation={5} sx={{ padding: 4, borderRadius: 3, width: "100%" }}>
      <Controls.Text variant="h5" gutterBottom text="Statistiche Allenamenti" />
      {presencesPercentual > 0 ? (
        <>
          <Controls.Text
            variant="body1"
            gutterBottom
            text={`Percentuale di presenza: ${presencesPercentual}%`}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Charts.PieChart data={presenceCounts} />
          </Box>
        </>
      ) : (
        <Controls.Text text="Nessuna presenza registrata" />
      )}
      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
      <Controls.Text variant="h5" gutterBottom text="Statistiche Partite" />
      {callupsPercentual > 0 ? (
        <>
          <Controls.Text
            variant="body1"
            gutterBottom
            text={`Percentuale di convocazione: ${callupsPercentual}%`}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Charts.PieChart data={matchTypeCounts} />
          </Box>
        </>
      ) : (
        <Controls.Text text="Nessuna convocazione registrata" />
      )}
    </Paper>
  )
}

export default MiniPlayerStats
