import React from "react"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  Menu,
  ListSubheader,
} from "@mui/material"

const Select = (props) => {
  const { name, label, value, onChange, options, width } = props

  // Configura il menu con supporto per lo scrolling
  const MenuProps = {
    PaperProps: {
      sx: {
        maxHeight: 300, // Altezza massima per il menu
      },
    },
  }

  return (
    <FormControl variant="outlined" sx={{ width: "100%", height: "40px" }}>
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        MenuProps={MenuProps}
        sx={{
          height: "60px",
          width: width || "100%",
          "& .MuiOutlinedInput-input": {
            padding: "10px 14px",
          },
          "& .MuiSelect-select": {
            padding: "10px 14px",
          },
        }}
      >
        <MenuItem value="">Nessuna Selezione</MenuItem>
        {options.map((item) => (
          <MenuItem value={item.id} key={item.id}>
            {item.title}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  )
}

export default Select
