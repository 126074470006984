import React from "react"
import MUIDataTable from "mui-datatables"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { CssBaseline } from "@mui/material"

const Datatable = ({ title, data, columns }) => {
  // Configurazione delle opzioni della tabella
  const options = {
    filter: false,
    selectableRows: "none",
    rowsPerPageOptions: [5, 10, 15, 20, 25, 50, 100],
    responsive: "standard",
    print: false,
    download: false,
    viewColumns: false,
    rowHover: true,
    selectableRowsHeader: false,
    elevation: 3,
    rowsPerPage: 20,
    textLabels: {
      body: {
        noMatch: "Nessun risultato trovato",
      },
      toolbar: {
        search: "Cerca",
        viewColumns: "Visualizza colonne",
        filterTable: "Filtra tabella",
      },
      pagination: {
        next: "Pagina Successiva",
        previous: "Pagina Precedente",
        rowsPerPage: "Righe per pagina:",
        displayRows: "di",
      },
      viewColumns: {
        title: "Colonne Visibili",
        titleAria: "Mostra/Nascondi colonne",
      },
      selectedRows: {
        text: "righe selezionate",
        delete: "Cancella",
        deleteAria: "Cancella righe selezionate",
      },
    },
    customTableBodyRender: (value) => <p className="capitalize">{value}</p>, // Example for custom rendering
  }

  // Funzione per creare il tema personalizzato
  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTable: {
          styleOverrides: {
            root: {
              borderRadius: "12px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)", // Adjusted shadow
              overflowX: "auto", // Enable horizontal scrolling
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              textAlign: "left",
              padding: "10px 15px",
            },
            head: {
              fontWeight: "bold",
              fontSize: "1.1rem",
              textTransform: "uppercase",
              backgroundColor: "#f5f5f5",
              color: "#333",
              padding: "12px 15px",
            },
            body: {
              padding: "12px 15px",
              backgroundColor: "#fdfdfd",
              color: "#444",
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              boxShadow: "0 3px 10px rgba(0, 0, 0, 0.5)", // Ensure Paper shadow is visible
              borderRadius: "8px",
              overflow: "hidden",
            },
          },
        },
        MuiTableRow: {
          styleOverrides: {
            root: {
              "&:nth-of-type(odd)": {
                backgroundColor: "#f7f7f9",
              },
              "&:hover": {
                backgroundColor: "#e6f7ff",
              },
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            root: {
              padding: "10px 15px",
              display: "flex",
            },
          },
        },
        MuiTablePagination: {
          styleOverrides: {
            toolbar: {
              backgroundColor: "#fafafa",
            },
          },
        },
      },
    })

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={getMuiTheme()}>
        <div>
          {/* Container with horizontal scroll */}
          <MUIDataTable data={data} columns={columns} options={options} />
        </div>
      </ThemeProvider>
    </>
  )
}

export default Datatable
