import React, { useState } from "react"
import { useParams, useLocation } from "react-router-dom"
import PlayerForm from "../components/PlayerForm"
import { Grid, Box } from "@mui/material"
import MiniPlayerStats from "../components/MiniPlayerStats"

const Player = () => {
  const { id } = useParams()
  const location = useLocation()
  const teamId = location.state?.team

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 3 }}
      style={{ marginTop: 30, width: "100%" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={8}>
          <PlayerForm id={id} idTeam={teamId} />
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <MiniPlayerStats teamId={teamId} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Player
