import React, { useState } from "react"
import { makeStyles } from "@material-ui/core"

// Custom hook for form state management
export function useForm(initialFValues) {
  const [values, setValues] = useState(initialFValues)

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setValues({
      ...values,
      [name]: value,
    })
  }

  return {
    values,
    setValues,
    handleInputChange,
  }
}

// Styles for the form component
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "95%",
      margin: theme.spacing(1), // spacing between form controls
    },
  },
}))

// Functional component for form layout
export function FormComponent(props) {
  const classes = useStyles()

  return (
    <form className={classes.root} autoComplete="off">
      {props.children}
    </form>
  )
}
