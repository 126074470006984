import React, { useEffect } from "react"
import { styled, useTheme } from "@mui/material/styles"
import Box from "@mui/material/Box"
import MuiDrawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import CssBaseline from "@mui/material/CssBaseline"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import PersonIcon from "@mui/icons-material/Person"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer"
import PieChartIcon from "@mui/icons-material/PieChart"
import { useNavigate, useLocation } from "react-router-dom"
import { useAppStore } from "../appStore"
import useMediaQuery from "@mui/material/useMediaQuery"

const drawerWidth = 240

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}))

// Rimuovi la prop `isMobile` prima di passare a `MuiDrawer`
const Drawer = styled(({ open, ...rest }) => <MuiDrawer {...rest} />)(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  })
)

const menuItems = [
  { text: "Giocatori", icon: <PersonIcon />, path: "/" },
  { text: "Allenamenti", icon: <CalendarMonthIcon />, path: "/trainings" },
  { text: "Impegni", icon: <SportsSoccerIcon />, path: "/matches" },
  { text: "Statistiche", icon: <PieChartIcon />, path: "/stats" },
]

const MenuItem = ({ text, icon, path, open, navigate, active }) => {
  return (
    <ListItem
      disablePadding
      sx={{ display: "block" }}
      onClick={() => navigate(path)}
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
          backgroundColor: active ? "primary.main" : "inherit",
          "&:hover": {
            backgroundColor: active
              ? "rgba(0, 0, 0, 0.15)"
              : "rgba(0, 0, 0, 0.04)",
          },
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "center",
            color: active ? "white" : "inherit",
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={text}
          sx={{
            opacity: open ? 1 : 0,
            color: active ? "white" : "inherit",
          }}
        />
      </ListItemButton>
    </ListItem>
  )
}

const isActivePath = (pathname, path) => {
  if (pathname === path) return true
  if (path === "/" && pathname.startsWith("/player")) return true
  if (path === "/trainings" && pathname.startsWith("/training")) return true
  if (path === "/matches" && pathname.startsWith("/match")) return true
  return false
}

export default function Sidenav() {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const open = useAppStore((state) => state.dopen)
  const updateOpen = useAppStore((state) => state.updateOpen)
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  useEffect(() => {
    if (isMobile) {
      updateOpen(false) // Chiudi il drawer sui dispositivi mobili quando la pagina cambia
    }
  }, [location.pathname, isMobile, updateOpen])

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* Usa condizionalmente display: none per nascondere il Drawer */}
      <Drawer
        variant="permanent"
        open={open}
        sx={{ display: isMobile && !open ? "none" : "block" }}
      >
        <DrawerHeader>
          <IconButton onClick={() => updateOpen(!open)}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.map((item, index) => (
            <MenuItem
              key={index}
              text={item.text}
              icon={item.icon}
              path={item.path}
              open={open}
              navigate={navigate}
              active={isActivePath(location.pathname, item.path)}
            />
          ))}
        </List>
      </Drawer>
    </Box>
  )
}
