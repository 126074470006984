import * as React from "react"
import { PieChart as MuiPieChart } from "@mui/x-charts/PieChart"

export default function PieChart({ data }) {
  const [toShow, setToShow] = React.useState([])

  React.useEffect(() => {
    if (data) {
      const toShow = data.map((item) => ({
        label: item.label,
        value: item.value,
        color: item.color,
      }))
      setToShow(toShow)
    }
  }, [data])

  return (
    <MuiPieChart
      series={[
        {
          data: toShow,
          highlightScope: { faded: "global", highlighted: "item" },
          faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
        },
      ]}
      width={400}
      height={180}
    />
  )
}
