import React from "react"
import Box from "@mui/material/Box"

const Home = () => {
  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}></Box>
    </>
  )
}

export default Home
