import React from "react"
import {
  FormControl,
  Select,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  ListItemIcon,
} from "@mui/material"

const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
    },
  },
}

const MultipleSelect = (props) => {
  const { selectedPlayers, label, options, onChange, name } = props

  const isAllSelected =
    options.length > 0 && selectedPlayers.length === options.length

  const handleChange = (event) => {
    const {
      target: { value },
    } = event

    let newSelectedPlayers

    if (value.includes("all")) {
      newSelectedPlayers = isAllSelected ? [] : options.map((option) => option)
    } else {
      const selectedIds = typeof value === "string" ? value.split(",") : value
      newSelectedPlayers = options.filter((option) =>
        selectedIds.includes(option.id)
      )
    }

    onChange({
      target: {
        name: name,
        value: newSelectedPlayers,
      },
    })
  }

  return (
    <div>
      <FormControl sx={{ m: 1, width: "100%" }}>
        <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedPlayers}
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
          renderValue={(selected) => {
            const selectedTitles = options
              .filter((option) => selected.includes(option.id))
              .map((option) => option.player)
            return selectedTitles.join(", ")
          }}
          MenuProps={MenuProps}
          sx={{
            height: "40px",
            width: "100%",
            "& .MuiOutlinedInput-input": {
              padding: "10px 14px",
            },
            "& .MuiSelect-select": {
              padding: "10px 14px",
            },
          }}
        >
          <MenuItem value="all">
            <ListItemIcon>
              <Checkbox
                checked={isAllSelected}
                indeterminate={
                  selectedPlayers.length > 0 &&
                  selectedPlayers.length < options.length
                }
              />
            </ListItemIcon>
            <ListItemText primary="Seleziona tutti" />
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              <Checkbox checked={selectedPlayers.indexOf(option.id) > -1} />
              <ListItemText primary={option.player} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default MultipleSelect
