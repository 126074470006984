import React, { useEffect } from "react"
import Controls from "./controls/Controls"
import { Paper, Box } from "@mui/material"

const ConvocationText = (props) => {
  const { text, setText } = props // ricevi anche setText per aggiornare il testo dall'area di testo

  // Sincronizza l'area di testo con il testo ricevuto
  useEffect(() => {
    setText(text)
  }, [text, setText])

  // Funzione per gestire i cambiamenti nel TextArea
  const handleChange = (event) => {
    setText(event.target.value)
  }

  return (
    <Paper elevation={5} sx={{ padding: 4, borderRadius: 3, width: "100%" }}>
      <Controls.Text variant="h5" gutterBottom text="Convocazione" />
      <Controls.TextArea value={text} onChange={handleChange} />
    </Paper>
  )
}

export default ConvocationText
