import React, { useState, useEffect, useContext } from "react"
import { Grid, Paper, Box } from "@mui/material"
import { useForm } from "./useForm"
import Controls from "./controls/Controls"
import Charts from "./charts/Charts"
import { DataContext } from "../context/DataContext"
import {
  countPlayerMatches,
  countPlayerTrainings,
  countTotalPresences,
  countPlayerCallUps,
} from "./statsFunctions"

const PlayerStats = ({
  filteredTrainings,
  filteredMatches,
  filteredPlayers,
}) => {
  const { values, setValues, handleInputChange } = useForm({
    selectedPlayer: "", // Inizialmente vuoto
  })

  const [playerMatchesCount, setPlayerMatchesCount] = useState(0)
  const [playerTrainingsCount, setPlayerTrainingsCount] = useState(0)
  const [matchTypeCounts, setMatchTypeCounts] = useState([])
  const [presenceCounts, setPresenceCounts] = useState([])
  const [countPlayerPresences, setCountPlayerPresences] = useState(0)
  const [countPlayerNonPresences, setCountPlayerNonPresences] = useState(0)
  const [presencesPercentual, setPresencesPercentual] = useState(0)
  const [countPlayerCallups, setCountPlayerCallups] = useState(0)
  const [callupsPercentual, setCallupsPercentual] = useState(0)

  const playerOptions = filteredPlayers.map((player) => ({
    id: player.id,
    title: `${player.name} ${player.surname}`,
  }))

  useEffect(() => {
    if (filteredPlayers.length > 0) {
      setValues((prevValues) => ({
        ...prevValues,
        selectedPlayer: filteredPlayers[0].id,
      }))
    }
  }, [filteredPlayers, setValues])

  useEffect(() => {
    if (filteredMatches && values.selectedPlayer) {
      const playerId = values.selectedPlayer

      const { matchCount, matchTypeArray } = countPlayerMatches(
        filteredMatches,
        playerId
      )
      const { trainingCount, presenceArray } = countPlayerTrainings(
        filteredTrainings,
        playerId
      )
      const totalPresences = countTotalPresences(filteredTrainings, playerId)
      const callUps = countPlayerCallUps(filteredMatches, playerId)
      setPlayerMatchesCount(matchCount)
      setPlayerTrainingsCount(trainingCount)
      setMatchTypeCounts(matchTypeArray)
      setPresenceCounts(presenceArray)
      setCountPlayerPresences(totalPresences)
      setCountPlayerNonPresences(filteredTrainings.length - totalPresences)

      // Calcolo della percentuale di presenza
      const presencePercent =
        filteredTrainings.length > 0
          ? (totalPresences / filteredTrainings.length) * 100
          : 0
      setPresencesPercentual(parseFloat(presencePercent.toFixed(2)))

      // Calcolo della percentuale di convocazioni
      const callupsPercent =
        filteredMatches.length > 0
          ? (callUps / filteredMatches.length) * 100
          : 0
      setCallupsPercentual(parseFloat(callupsPercent.toFixed(2)))
    }
  }, [filteredMatches, filteredTrainings, values.selectedPlayer])

  return (
    <Box
      sx={{
        display: "flex",
        padding: 0.5,
      }}
    >
      <Paper elevation={5} sx={{ padding: 4, borderRadius: 3, width: "100%" }}>
        <Controls.Text variant="h5" gutterBottom text="Statistiche Giocatore" />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginTop: 3,
          }}
        >
          <Controls.Select
            name="selectedPlayer"
            label="Giocatore Selezionato"
            value={values.selectedPlayer}
            onChange={handleInputChange}
            options={playerOptions}
            width="95%"
          />
        </Box>
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          <Grid item xs={12} md={4}>
            <Controls.Text
              variant="body1"
              gutterBottom
              text={`Impegni totali (Allenamenti + Partite): ${
                playerMatchesCount + playerTrainingsCount
              }`}
            />
            <Controls.Text
              variant="body1"
              gutterBottom
              text={`Totale partite: ${playerMatchesCount}`}
            />
            <Controls.Text
              variant="body1"
              gutterBottom
              text={`Totale allenamenti: ${playerTrainingsCount}`}
            />
            {isNaN(presencesPercentual) || presencesPercentual <= 0 ? null : (
              <Controls.Text
                variant="body1"
                gutterBottom
                text={`Percentuale di presenza allenamento: ${presencesPercentual}%`}
              />
            )}
            {isNaN(callupsPercentual) || callupsPercentual <= 0 ? null : (
              <Controls.Text
                variant="body1"
                gutterBottom
                text={`Percentuale di convocazione: ${callupsPercentual}%`}
              />
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <Controls.Text variant="h5" gutterBottom text="Allenamenti" />
            {filteredTrainings.length > 0 ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Charts.PieChart data={presenceCounts} />
              </Box>
            ) : (
              <Controls.Text text="Nessun allenamento registrato" />
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <Controls.Text variant="h5" gutterBottom text="Partite" />
            {presencesPercentual > 0 ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Charts.PieChart data={matchTypeCounts} />
              </Box>
            ) : (
              <Controls.Text text="Nessuna convocazione registrata" />
            )}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default PlayerStats
