import React, { useState } from "react"
import { useParams, useLocation } from "react-router-dom"
import { Grid, Box } from "@mui/material"
import MatchForm from "../components/MatchForm"
import ConvocationText from "../components/ConvocationText"

const Match = () => {
  const { id } = useParams()
  const location = useLocation()
  const teamId = location.state?.team
  const [text, setText] = useState("")

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 3, overflowX: "auto" }}
      style={{ marginTop: 30, width: "100%" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={8}>
          <MatchForm text={text} setText={setText} id={id} idTeam={teamId} />
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <ConvocationText text={text} setText={setText} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Match
