import React, { useContext, useState, useEffect } from "react"
import { DataContext } from "../context/DataContext"
import { Grid, Box } from "@mui/material"
import GeneralStats from "../components/GeneralStats"
import PlayerStats from "../components/PlayerStats"
import Controls from "../components/controls/Controls"
import { useForm } from "../components/useForm"

const Stats = () => {
  const {
    trainings,
    teams,
    players,
    teamId,
    matches,
    loading,
    error,
    selectedTeam,
    setSelectedTeam,
  } = useContext(DataContext)
  const [filteredTrainings, setFilteredTrainings] = useState([])
  const [filteredPlayers, setFilteredPlayers] = useState([])
  const [filteredMatches, setFilteredMatches] = useState([])
  // Imposta le opzioni del selettore, escludendo il team con id 8
  const teamsOptions = teams
    .filter((team) => team.id !== "8") // Filtra per escludere l'id 8
    .map((team) => ({
      id: team.id,
      title: team.name,
    }))

  useEffect(() => {
    if (trainings) {
      const filteredTrainings = selectedTeam
        ? trainings.filter(
            (item) => item.team_id === selectedTeam && item.done === "Fatto"
          )
        : trainings.filter((item) => item.done === "Fatto")

      const filteredPlayers = selectedTeam
        ? players.filter((item) => item.team_id === selectedTeam)
        : players

      const filteredMatches = selectedTeam
        ? matches.filter((item) => item.team_id === selectedTeam)
        : matches

      setFilteredTrainings(filteredTrainings)
      setFilteredPlayers(filteredPlayers)
      setFilteredMatches(filteredMatches)
    }
  }, [trainings, selectedTeam, players, matches])

  const changeSelectedTeam = (e) => {
    setSelectedTeam(e.target.value)
  }

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 3 }}
      style={{ marginTop: 30, width: "100%" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          {teamId === "8" && (
            <Controls.Select
              name="selectedTeam"
              label="Squadra"
              value={selectedTeam}
              onChange={changeSelectedTeam}
              options={teamsOptions}
            />
          )}
        </Grid>
        {teamId !== "8" || (teamId === "8" && selectedTeam) ? (
          <Grid item xs={12} md={12} lg={12}>
            <Grid item xs={12} md={12} lg={12}>
              <GeneralStats
                filteredTrainings={filteredTrainings}
                filteredMatches={filteredMatches}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ marginTop: 2 }}>
              <PlayerStats
                filteredTrainings={filteredTrainings}
                filteredMatches={filteredMatches}
                filteredPlayers={filteredPlayers}
              />
            </Grid>
          </Grid>
        ) : (
          <Box
            component="main"
            sx={{ flexGrow: 1, p: 3 }}
            style={{
              marginTop: 30,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Controls.Text
              variant="h5"
              gutterBottom
              text="Seleziona una squadra per visualizzare le statistiche"
            />
          </Box>
        )}
      </Grid>
    </Box>
  )
}

export default Stats
