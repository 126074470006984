import React, { useState } from "react"
import { useAuth } from "../context/Auth"
import { useNavigate } from "react-router-dom"
import { Grid, Paper, Box, Avatar, Typography } from "@mui/material"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import Controls from "../components/controls/Controls"

const Login = () => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const { login } = useAuth()
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    setError("") // Reset error message

    try {
      const response = await login(username, password)

      if (response.loggedIn) {
        // Redirect to the homepage or wherever you want after successful login
        window.location.href = "/"
      } else {
        setError("Credenziali errate")
      }
    } catch (error) {
      setError("Errore durante il login. Per favore riprova.")
    } finally {
      setIsLoading(false)
    }
  }

  const paperStyle = {
    padding: 30,
    width: "100%",
    maxWidth: 400,
    margin: "20px",
    borderRadius: 8,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  }

  const avatarStyle = { backgroundColor: "#3f51b5" } // Primary color

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh", backgroundColor: "#f5f5f5" }}
    >
      <Paper elevation={3} style={paperStyle}>
        <Grid container direction="column" alignItems="center">
          <Avatar style={avatarStyle}>
            <LockOutlinedIcon />
          </Avatar>
          <Box sx={{ marginTop: 2 }} />
          <Typography variant="h4" component="h1" gutterBottom>
            Accedi
          </Typography>
          <Box sx={{ marginTop: 2 }} />
          <Controls.Input
            label="Username"
            placeholder="Inserisci il tuo username"
            fullWidth
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            sx={{ marginTop: 2 }}
          />
          <Controls.Input
            label="Password"
            placeholder="Inserisci la tua password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            style={{ marginTop: 2 }}
          />
          {error && (
            <Typography
              variant="body2"
              color="error"
              align="center"
              gutterBottom
              sx={{ marginTop: 2 }}
            >
              {error}
            </Typography>
          )}
          <Controls.Button
            text={isLoading ? "Accedi..." : "Accedi"}
            color="primary"
            fullWidth
            onClick={handleSubmit}
            disabled={isLoading}
            sx={{ marginTop: 2 }}
          />
        </Grid>
      </Paper>
    </Grid>
  )
}

export default Login
