import Button from "./Button"
import Input from "./Input"
import MultipleSelect from "./MultipleSelect"
import RadioGroup from "./RadioGroup"
import Select from "./Select"
import Text from "./Text"
import TextArea from "./TextArea"
import DateInput from "./DateInput"

const Controls = {
  Input,
  RadioGroup,
  Select,
  Button,
  Text,
  MultipleSelect,
  TextArea,
  DateInput,
}

export default Controls
