import React from "react"
import Textarea from "@mui/joy/Textarea"

const TextArea = (props) => {
  const { value, onChange } = props // Destructuring delle props
  return (
    <Textarea
      placeholder="Testo convocazione..."
      value={value} // Imposta il valore corrente del testo
      sx={{ width: "100%" }}
      onChange={onChange} // Aggiorna il testo quando cambia
    />
  )
}

export default TextArea
