import React, { useContext, useEffect, useState } from "react"
import { DataContext } from "../context/DataContext"
import { Grid, Paper, Box, LinearProgress } from "@mui/material"
import Controls from "./controls/Controls"

const GeneralStats = ({ filteredTrainings, filteredMatches }) => {
  const [presenti, totale] = [27, 30]
  const percentuale = (presenti / totale) * 100
  const [nTrainings, setNTrainings] = useState(0)
  const [nMatches, setNMatches] = useState(0)
  const [matchCounts, setMatchCounts] = useState({
    Amichevole: 0,
    Campionato: 0,
    Torneo: 0,
    Liga: 0,
  })

  useEffect(() => {
    if (filteredTrainings) {
      setNTrainings(filteredTrainings.length)
    }
    if (filteredMatches) {
      let matchCount = 0
      filteredMatches.forEach((match) => {
        matchCount += parseInt(match.numMatches)
      })

      setNMatches(matchCount)

      // Conta le occorrenze di ogni tipo di match
      const counts = { Amichevole: 0, Campionato: 0, Torneo: 0, Liga: 0 }
      filteredMatches.forEach((match) => {
        const type = match.type
        if (counts[type] !== undefined) {
          counts[type]++
        }
      })
      setMatchCounts(counts)
    }
  }, [filteredTrainings, filteredMatches])

  const cardSx = {
    padding: 4,
    borderRadius: 3,
    width: "100%",
    height: 150,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center", // Centra verticalmente
    alignItems: "center", // Centra orizzontalmente
    textAlign: "center", // Allinea il testo al centro
  }

  return (
    <Box
      sx={{
        display: "flex",
        padding: 0.5,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6} md={4} lg={2}>
          <Paper elevation={5} sx={cardSx}>
            <Controls.Text gutterBottom text="Totale Partite" />
            <Controls.Text variant="h3" gutterBottom text={`${nMatches}`} />
          </Paper>
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <Paper elevation={5} sx={cardSx}>
            <Controls.Text gutterBottom text="Totale Allenamenti" />
            <Controls.Text variant="h3" gutterBottom text={`${nTrainings}`} />
          </Paper>
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <Paper elevation={5} sx={cardSx}>
            <Controls.Text variant="body1" gutterBottom text="Tornei" />
            <Controls.Text
              variant="h3"
              gutterBottom
              text={`${matchCounts.Torneo}`}
            />
          </Paper>
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <Paper elevation={5} sx={cardSx}>
            <Controls.Text variant="body1" gutterBottom text="Campionato" />
            <Controls.Text
              variant="h3"
              gutterBottom
              text={`${matchCounts.Campionato}`}
            />
          </Paper>
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <Paper elevation={5} sx={cardSx}>
            <Controls.Text variant="body1" gutterBottom text="Liga" />
            <Controls.Text
              variant="h3"
              gutterBottom
              text={`${matchCounts.Liga}`}
            />
          </Paper>
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <Paper elevation={5} sx={cardSx}>
            <Controls.Text variant="body1" gutterBottom text="Amichevoli" />
            <Controls.Text
              variant="h3"
              gutterBottom
              text={`${matchCounts.Amichevole}`}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}

export default GeneralStats
