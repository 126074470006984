import React from "react"
import { Typography } from "@mui/material"

const Text = (props) => {
  const { variant, gutterBottom, text } = props
  return (
    <Typography variant={variant} gutterBottom={gutterBottom}>
      {text}
    </Typography>
  )
}

export default Text
