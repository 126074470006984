export const countPlayerMatches = (matches, playerId) => {
  const matchTypeCounter = {
    Amichevole: 0,
    Torneo: 0,
    Campionato: 0,
    Liga: 0,
  }

  let matchCount = 0
  matches.forEach((match) => {
    const calledUps = JSON.parse(match.calledups) // Parse del campo calledups
    if (calledUps.some((player) => player.id === playerId)) {
      matchCount += parseInt(match.numMatches)
      matchTypeCounter[match.type]++
    }
  })

  const matchTypeArray = [
    {
      value: matchTypeCounter.Amichevole,
      label: "Amichevole",
      color: "#007bff", // Blu
    },
    {
      value: matchTypeCounter.Torneo,
      label: "Torneo",
      color: "#28a745", // Verde
    },
    {
      value: matchTypeCounter.Campionato,
      label: "Campionato",
      color: "#dc3545", // Rosso
    },
    {
      value: matchTypeCounter.Liga,
      label: "Liga",
      color: "#fd7e14", // Arancione
    },
  ]

  return {
    matchCount,
    matchTypeArray,
  }
}

export const countPlayerTrainings = (trainings, playerId) => {
  const presenceCounter = {
    Presente: 0,
    "Assente Giustificato": 0,
    "Assente Ingiustificato": 0,
    Infortunato: 0,
    Malato: 0,
  }

  let trainingCount = 0

  trainings.forEach((training) => {
    const presences = JSON.parse(training.presences) // Parse del campo presences
    if (presences[playerId]) {
      presenceCounter[presences[playerId]]++
    }
    if (presences[playerId] === "Presente") {
      trainingCount++
    }
  })

  const presenceArray = [
    { value: presenceCounter.Presente, label: "P", color: "#28a745" }, // Blu
    {
      value: presenceCounter["Assente Giustificato"],
      label: "AG",
      color: "#007bff",
    }, // Verde
    {
      value: presenceCounter["Assente Ingiustificato"],
      label: "AI",
      color: "#dc3545",
    }, // Rosso
    { value: presenceCounter.Infortunato, label: "I", color: "#ffc107" }, // Giallo
    { value: presenceCounter.Malato, label: "M", color: "#6f42c1" }, // Viola
  ]

  return {
    trainingCount,
    presenceArray,
  }
}

export const countTotalPresences = (trainings, playerId) => {
  const presenceCounter = {
    Presente: 0,
    "Assente Giustificato": 0,
    "Assente Ingiustificato": 0,
    Infortunato: 0,
    Malato: 0,
  }

  trainings.forEach((training) => {
    const presences = JSON.parse(training.presences) // Parse del campo presences
    if (presences[playerId]) {
      presenceCounter[presences[playerId]]++
    }
  })

  return presenceCounter.Presente
}

export const countPlayerCallUps = (matches, playerId) => {
  let callUpCount = 0
  matches.forEach((match) => {
    const calledUps = JSON.parse(match.calledups) // Parse del campo calledups
    if (calledUps.some((player) => player.id === playerId)) {
      callUpCount++
    }
  })
  return callUpCount
}
