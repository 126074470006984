import React from "react"
import { DatePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import TextField from "@mui/material/TextField"
import "dayjs/locale/it" // Importa il locale italiano
import localeData from "dayjs/plugin/localeData" // Importa il plugin localeData
import updateLocale from "dayjs/plugin/updateLocale" // Importa il plugin updateLocale

// Configura dayjs per usare il locale italiano
dayjs.locale("it")
dayjs.extend(localeData) // Estendi dayjs con il plugin localeData
dayjs.extend(updateLocale) // Estendi dayjs con il plugin updateLocale

// Imposta il lunedì come primo giorno della settimana per il locale italiano
dayjs.updateLocale("it", {
  weekStart: 1,
})

const DateInput = ({ name, label, value, onChange, style, required }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
      <DatePicker
        name={name}
        label={label}
        value={value ? dayjs(value) : null}
        onChange={(newValue) => onChange(newValue ? newValue : "")}
        format="DD/MM/YYYY"
        localeText={{
          firstDayOfWeek: 1, // Imposta il lunedì come primo giorno
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            required={required}
            style={style}
          />
        )}
        sx={{
          "& .MuiOutlinedInput-root": {
            height: "40px",
          },
          "& .MuiOutlinedInput-input": {
            padding: "10px 14px",
          },
          ...style,
        }}
      />
    </LocalizationProvider>
  )
}

export default DateInput
