import React, { createContext, useState, useEffect } from "react"
import { getData } from "../backend/server"
import { useAuth } from "./Auth"

const DataContext = createContext()

const DataProvider = ({ children }) => {
  const [players, setPlayers] = useState([])
  const [matches, setMatches] = useState([])
  const [trainings, setTrainings] = useState([])
  const [teams, setTeams] = useState([])
  const [loading, setLoading] = useState(true)
  const [teamId, setTeamId] = useState(null)
  const [error, setError] = useState(null)
  const [selectedTeam, setSelectedTeam] = useState(null)

  const { team_id, loadingAuth, logout } = useAuth()

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true)
      try {
        if (!loadingAuth && team_id) {
          setTeamId(team_id)

          const result = await getData(team_id)
          if (result) {
            setPlayers(result.players || [])
            setTrainings(result.trainings || [])
            setMatches(result.matches || [])
            if (team_id.toString() === "8") {
              setTeams(result.teams || [])
            }
          }
        } else {
          // Reset data when team_id is null (logout case)
          setPlayers([])
          setMatches([])
          setTrainings([])
          setTeams([])
          setError(null)
        }
      } catch (error) {
        setError(error.message || "Si è verificato un errore.")
      } finally {
        setLoading(false)
      }
    }

    fetchAllData()
  }, [team_id, teamId, loadingAuth])

  return (
    <DataContext.Provider
      value={{
        players,
        matches,
        trainings,
        teams,
        teamId,
        loading,
        error,
        selectedTeam,
        setSelectedTeam,
      }}
    >
      {children}
    </DataContext.Provider>
  )
}

export { DataContext, DataProvider }
