import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import "./index.css"
import App from "./App"
import { DataProvider } from "./context/DataContext"
import { AuthProvider } from "./context/Auth" // Importa AuthProvider

const rootElement = document.getElementById("root")
const root = createRoot(rootElement)

root.render(
  <StrictMode>
    <AuthProvider>
      {" "}
      {/* AuthProvider deve essere il genitore */}
      <DataProvider>
        <App />
      </DataProvider>
    </AuthProvider>
  </StrictMode>
)
