import { set } from "date-fns"
import React, { createContext, useState, useContext, useEffect } from "react"
import config from "../config"

const AuthContext = createContext()

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null)
  const [team_id, setTeamId] = useState(null)
  const [loadingAuth, setLoading] = useState(true)

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/check_auth.php`, {
          method: "GET",
          credentials: "include", // Include cookies in requests
          headers: {
            "Content-Type": "application/json",
          },
        })

        if (!response.ok) {
          throw new Error("Errore durante la verifica dell'autenticazione")
        }

        const data = await response.json()

        if (data.loggedIn) {
          setUser(data.username)
          setTeamId(data.team_id)
        } else {
          setUser(null)
          setTeamId(null)
        }
      } catch (error) {
        console.error("Errore durante la verifica dell'autenticazione:", error)
        setUser(null)
        setTeamId(null)
      } finally {
        setLoading(false)
      }
    }

    checkAuth()
  }, [])

  const login = async (username, password) => {
    try {
      const response = await fetch(`${config.apiUrl}/login.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Include cookies in requests
        body: JSON.stringify({ username, password }),
      })

      setUser(null)
      setTeamId(null)

      const data = await response.json()

      if (data.loggedIn) {
        setUser(username)
        setTeamId(data.team_id)
        return data
      } else {
        throw new Error(data.message || "Credenziali non valide")
      }
    } catch (error) {
      console.error("Errore durante il login:", error)
      throw error
    }
  }

  const logout = async () => {
    try {
      await fetch(`${config.apiUrl}/logout.php`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
      setUser(null)
      setTeamId(null)
    } catch (error) {
      console.error("Errore durante il logout:", error)
    }
  }

  return (
    <AuthContext.Provider value={{ user, loadingAuth, team_id, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}
