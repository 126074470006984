import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import config from "../config"

const Register = () => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState(null)
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      // Costruisci l'oggetto dei dati da inviare
      const response = await fetch(`${config.apiUrl}/register.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          password,
        }),
      })

      // Controlla se la risposta è ok
      if (!response.ok) {
        throw new Error("Errore durante la registrazione")
      }

      const data = await response.json()

      if (data.message === "Registration successful") {
        navigate("/login")
      } else {
        setError(data.error || "Errore durante la registrazione")
      }
    } catch (error) {
      setError("Errore durante la registrazione: " + error.message)
    }
  }

  return (
    <div style={{ marginTop: 100 }}>
      <h2>Registrazione</h2>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Registrati</button>
      </form>
    </div>
  )
}

export default Register
