import React from "react"
import {
  Routes,
  Route,
  BrowserRouter,
  Navigate,
  useLocation,
} from "react-router-dom"
import Box from "@mui/material/Box"
import Sidenav from "./components/Sidenav"
import Navbar from "./components/Navbar"
import Home from "./pages/Home"
import Players from "./pages/Players"
import Player from "./pages/Player"
import Trainings from "./pages/Trainings"
import Training from "./pages/Training"
import Matches from "./pages/Matches"
import Match from "./pages/Match"
import Stats from "./pages/Stats"
import Login from "./pages/Login"
import Register from "./pages/Register"
import { useAuth } from "./context/Auth"
import { CircularProgress } from "@mui/material"

const ProtectedRoute = ({ element }) => {
  const { user, team_id, loadingAuth } = useAuth()

  if (loadingAuth) {
    return (
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3 }}
        style={{
          marginTop: 30,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return user ? element : <Navigate to="/login" />
}

const AppContent = () => {
  const location = useLocation()

  // Check if the current path is the login page
  const isLoginPage =
    location.pathname === "/login" || location.pathname === "/register"

  return (
    <>
      {!isLoginPage && <Navbar />}
      {!isLoginPage && (
        <>
          <Box height={30} />
          <Box sx={{ display: "flex" }}>
            <Sidenav />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/register"
                element={<ProtectedRoute element={<Home />} />}
              />
              <Route
                path="/"
                element={<ProtectedRoute element={<Players />} />}
              />
              <Route
                path="/player/:id"
                element={<ProtectedRoute element={<Player />} />}
              />
              <Route
                path="/trainings"
                element={<ProtectedRoute element={<Trainings />} />}
              />
              <Route
                path="/training/:id"
                element={<ProtectedRoute element={<Training />} />}
              />
              <Route
                path="/matches"
                element={<ProtectedRoute element={<Matches />} />}
              />
              <Route
                path="/match/:id"
                element={<ProtectedRoute element={<Match />} />}
              />
              <Route
                path="/stats"
                element={<ProtectedRoute element={<Stats />} />}
              />
            </Routes>
          </Box>
        </>
      )}
      {isLoginPage && (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
        </Routes>
      )}
    </>
  )
}

const App = () => {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <AppContent />
      </BrowserRouter>
    </React.StrictMode>
  )
}

export default App
