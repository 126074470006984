import React, { useContext, useEffect, useState } from "react"
import dayjs from "dayjs" // Importa dayjs
import { DataContext } from "../context/DataContext"
import Box from "@mui/material/Box"
import Datatable from "../components/Datatable"
import { useNavigate } from "react-router-dom"
import { Grid, CircularProgress, IconButton } from "@mui/material"
import Controls from "../components/controls/Controls"
import EditIcon from "@mui/icons-material/Edit"
import { ToastContainer } from "react-toastify"

const Players = () => {
  const {
    players,
    teams,
    teamId,
    loading,
    error,
    selectedTeam,
    setSelectedTeam,
  } = useContext(DataContext)
  const [playersDataTable, setPlayersDataTable] = useState([])
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600)

  const teamsOptions = teams
    .filter((team) => team.id !== "8")
    .map((team) => ({
      id: team.id,
      title: team.name,
    }))

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600)
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    if (teamId) {
      const filteredPlayers = selectedTeam
        ? players
            .filter((item) => item.team_id === selectedTeam)
            .sort((a, b) => a.surname.localeCompare(b.surname))
        : players.sort((a, b) => a.surname.localeCompare(b.surname))

      setPlayersDataTable(
        filteredPlayers.map((item) => [
          item.id,
          item.surname,
          item.name,
          dayjs(item.birthdate).format("DD/MM/YYYY"), // Converti la data
          item.role,
        ])
      )
    }
  }, [players, selectedTeam, teamId])

  if (loading || teamId === null) {
    return (
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3 }}
        style={{
          marginTop: 30,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (error) return <div>Error: {error}</div>

  const columns = [
    { name: "ID", options: { display: false } },
    {
      name: "Cognome",
      options: {
        customBodyRender: (value) => <p className="capitalize">{value}</p>,
      },
    },
    {
      name: "Nome",
      options: {
        customBodyRender: (value) => <p className="capitalize">{value}</p>,
      },
    },
    {
      name: "Data di Nascita",
      options: {
        customBodyRender: (value) => <p className="capitalize">{value}</p>,
        display: isMobile ? "false" : "true",
      },
    },
    {
      name: "Ruolo",
      options: {
        customBodyRender: (value) => (
          <p
            className={`capitalize px-3 py-1 inline-block rounded-full text-white font-bold ${
              value === "Portiere"
                ? "bg-red-500"
                : value === "Difensore"
                ? "bg-green-500"
                : value === "Centrocampista"
                ? "bg-yellow-500"
                : value === "Attaccante"
                ? "bg-blue-500"
                : "bg-purple-500"
            }`}
          >
            {value}
          </p>
        ),
        display: isMobile ? "false" : "true", // Hide on mobile
      },
    },
    {
      name: "Azioni",
      options: {
        customBodyRender: (value, tableMeta) => (
          <Box
            sx={{
              display: "flex",
              gap: 1,
              justifyContent: "flex-end",
              flexWrap: "wrap",
            }}
          >
            <IconButton
              color="primary"
              onClick={() =>
                navigate(`/player/${tableMeta.rowData[0]}`, {
                  state: { team: selectedTeam },
                })
              }
            >
              <EditIcon />
            </IconButton>
          </Box>
        ),
        setCellProps: () => ({
          sx: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
            flexWrap: "wrap",
          },
        }),
      },
    },
  ]

  const changeSelectedTeam = (e) => {
    setSelectedTeam(e.target.value)
  }

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 3 }}
      style={{ marginTop: 30, width: "100%" }}
    >
      <ToastContainer />
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          {teamId === "8" && (
            <Controls.Select
              name="selectedTeam"
              label="Squadra"
              value={selectedTeam}
              onChange={changeSelectedTeam}
              options={teamsOptions}
              width="100%"
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{ display: "flex", justifyContent: "right" }}
        >
          {(teamId !== "8" || (teamId === "8" && selectedTeam)) && (
            <Controls.Button
              text="Aggiungi Giocatore"
              color="success"
              onClick={() =>
                navigate("/player/new", {
                  state: { team: selectedTeam },
                })
              }
            />
          )}
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          {teamId !== "8" || (teamId === "8" && selectedTeam) ? (
            <Datatable
              title="Giocatori"
              data={playersDataTable || []}
              columns={columns}
            />
          ) : (
            <Box
              component="main"
              sx={{ flexGrow: 1, p: 3 }}
              style={{
                marginTop: 30,
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Controls.Text
                variant="h5"
                gutterBottom
                text="Seleziona una squadra per visualizzare i giocatori"
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default Players
