import React from "react"
import { Typography, Modal as MuiModal, Box, Grid, Button } from "@mui/material"
import Controls from "./Controls" // Assicurati che Controls.Button sia un componente Material-UI Button o personalizzato

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: "80%", md: "50%" }, // Responsiveness
  maxWidth: 500,
  bgcolor: "white",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
}

export default function Modal({ open, onClose, onConfirm, title, text }) {
  return (
    <MuiModal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {text}
        </Typography>
        <Grid container spacing={2} mt={2} justifyContent="center">
          <Grid item xs={12} sm={6} md={4}>
            <Controls.Button
              text="Conferma"
              onClick={onConfirm}
              color="success"
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controls.Button
              text="Annulla"
              onClick={onClose}
              sx={{ width: "100%" }}
            />
          </Grid>
        </Grid>
      </Box>
    </MuiModal>
  )
}
