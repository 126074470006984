import config from "../config"

/* const response = await fetch(
  "https://teamhub.altervista.org/API/getData.php"
) */
export const getData = async (team_id) => {
  try {
    const response = await fetch(
      `${config.apiUrl}/getData.php?team_id=${team_id}`,
      {
        method: "GET",
        credentials: "include", // Include cookies in requests
        headers: {
          "Content-Type": "application/json",
        },
      }
    )

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`)
    }
    const data = await response.json()
    return data // Restituisci tutti i dati come un oggetto
  } catch (error) {
    console.error("Errore nella fetch:", error)
    return null
  }
}

export const insertData = async (table, data, contextObject) => {
  try {
    const response = await fetch(`${config.apiUrl}/insertData.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ table, insert: data }), // Invia la tabella e i dati di inserimento
    })

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`)
    }

    const text = await response.text()
    if (text) {
      const result = JSON.parse(text)
      if (result.status === "success") {
        // Aggiungi il nuovo record all'array contextObject
        contextObject.push({ id: result.id, ...data })
        return result.id
      } else {
        throw new Error(result.message || "Errore nell'inserimento")
      }
    } else {
      throw new Error("Empty response from server")
    }
  } catch (error) {
    console.error("Errore nell'inserimento:", error)
    throw error
  }
}

export const updateData = async (table, update, id, contextObject) => {
  try {
    const response = await fetch(`${config.apiUrl}/updateData.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ table, id, update }), // Invia la tabella, l'ID e i dati di aggiornamento
    })

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`)
    }

    const text = await response.text()
    if (text) {
      const result = JSON.parse(text)
      if (result.status === "success") {
        const index = contextObject.findIndex((item) => item.id === id)
        if (index !== -1) {
          contextObject[index] = {
            ...contextObject[index],
            ...update,
          }
        }
        return true
      } else {
        throw new Error(result.message || "Errore nell'aggiornamento")
      }
    } else {
      throw new Error("Empty response from server")
    }
  } catch (error) {
    console.error("Errore nell'update:", error)
    throw error
  }
}

export const deleteData = async (table, id, contextObject) => {
  try {
    const response = await fetch(`${config.apiUrl}/deleteData.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ table, id }), // Invia la tabella e l'ID da cancellare
    })

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`)
    }

    const text = await response.text()
    if (text) {
      const result = JSON.parse(text)
      if (result.status === "success") {
        // Rimuovi l'elemento dall'array contextObject
        const index = contextObject.findIndex((item) => item.id === id)
        if (index !== -1) {
          contextObject.splice(index, 1)
        }
        return true
      } else {
        throw new Error(result.message || "Errore nella cancellazione")
      }
    } else {
      throw new Error("Empty response from server")
    }
  } catch (error) {
    console.error("Errore nella cancellazione:", error)
    throw error
  }
}
