import React, { useContext, useEffect, useState } from "react"
import { DataContext } from "../context/DataContext"
import Checkbox from "@mui/material/Checkbox"
import Tooltip from "@mui/material/Tooltip"
import {
  ThemeProvider,
  createTheme,
  CssBaseline,
  Grid,
  Box,
} from "@mui/material"
import Datatable from "../components/Datatable"
import { useParams, useLocation } from "react-router-dom"
import TrainingForm from "../components/TrainingForm"

const Training = () => {
  const { id } = useParams()
  const location = useLocation()
  const teamId = location.state?.team
  const [selectedValues, setSelectedValues] = useState({})
  const { trainings, players } = useContext(DataContext)
  const [playersArray, setPlayersArray] = useState([])

  // Trova i dati dell'allenamento
  const trainingData = trainings.find((item) => item.id === id)

  useEffect(() => {
    // Filtra i giocatori in base al teamId
    const filteredPlayers = teamId
      ? players
          .filter((item) => item.team_id === teamId)
          .sort((a, b) => a.surname.localeCompare(b.surname))
      : players.sort((a, b) => a.surname.localeCompare(b.surname))

    // Genera playersArray da filteredPlayers
    const playersArray = filteredPlayers.map((item) => [
      item.id,
      `${item.surname} ${item.name}`,
    ])

    if (id !== "new" && trainingData) {
      // Carica i dati dal contesto per un allenamento esistente
      const presences = JSON.parse(trainingData.presences)
      setSelectedValues(presences)
    } else if (id === "new") {
      // Per un nuovo allenamento, imposta tutti i giocatori come "Presente"
      const newSelectedValues = filteredPlayers.reduce((acc, player) => {
        acc[player.id] = "Presente"
        return acc
      }, {})
      setSelectedValues(newSelectedValues)
    }

    // Imposta i dati della tabella dei giocatori
    setPlayersArray(playersArray)
  }, [id, trainingData, teamId, players])

  const handleCheckboxChange = (playerId, statusType) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [playerId]: statusType, // Aggiorna solo il valore selezionato
    }))
  }

  const columns = [
    { name: "ID", options: { display: false } },
    {
      name: "Giocatore",
      options: {
        customBodyRender: (value) => (
          <p className="capitalize" style={{ fontWeight: "bold" }}>
            {value}
          </p>
        ),
      },
    },
    ...[
      "Presente",
      "Assente Giustificato",
      "Assente Ingiustificato",
      "Infortunato",
      "Malato",
    ].map((status, index) => ({
      name: status,
      options: {
        customBodyRender: (value, tableMeta) => {
          const rowId = tableMeta.rowIndex
          const playerId = playersArray[rowId][0]
          const statusType = status
          const isChecked = selectedValues[playerId] === statusType

          return (
            <Tooltip title={status} key={`${playerId}-${statusType}`}>
              <Checkbox
                checked={isChecked}
                onChange={() => handleCheckboxChange(playerId, statusType)}
                color={
                  index === 0 ? "success" : index === 1 ? "default" : "warning"
                }
                inputProps={{ "aria-label": status }}
              />
            </Tooltip>
          )
        },
      },
    })),
  ]

  // Custom theme for the Datatable
  const customTheme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          head: {
            fontWeight: "bold",
            backgroundColor: "#f5f5f5",
            color: "#333",
            padding: "12px 16px",
            textAlign: "center",
          },
          body: {
            fontSize: "0.9rem",
            textAlign: "center",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            fontWeight: "600",
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
              backgroundColor: "#1565c0",
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            "&.Mui-checked": {
              color: "#4caf50",
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "0 3px 15px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            overflow: "hidden",
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&:nth-of-type(odd)": {
              backgroundColor: "#f7f7f9",
            },
            "&:hover": {
              backgroundColor: "#e3f2fd",
            },
          },
        },
      },
    },
  })

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 3 }}
      style={{ marginTop: 30, width: "100%" }}
    >
      <CssBaseline />
      <ThemeProvider theme={customTheme}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} lg={12}>
            <TrainingForm
              id={id}
              presences={selectedValues}
              players={playersArray}
              idTeam={teamId}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Datatable title="Presenze" data={playersArray} columns={columns} />
          </Grid>
        </Grid>
      </ThemeProvider>
    </Box>
  )
}

export default Training
